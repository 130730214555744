<template>
  <div class="common-wrapper">
    <LeftTree ref="leftTree" :treeData="treeData" :timeType="timeType" />
    <div class="content">
      <div>
        <a-tabs v-model:activeKey="timeType" @change="changeTimeTab">
          <a-tab-pane
            key="3"
            :disabled="leftTree && leftTree.meterType == '02'"
            tab="小时抄表"
          ></a-tab-pane>
          <a-tab-pane key="1" tab="日抄表"></a-tab-pane>
          <a-tab-pane key="2" tab="月抄表" force-render></a-tab-pane>
        </a-tabs>

        <basic-card :showTitle="false" titleHeight="56px">
          <template #left>
            <a-form layout="inline">
              <a-form-item>
                <a-range-picker
                  v-model:value="time"
                  :show-time="timeType == 3"
                  :picker="
                    timeType == 3 ? null : timeType == 1 ? 'date' : 'month'
                  "
                />
              </a-form-item>
              <a-form-item>
                <a-button type="primary" @click="initTableData">查询</a-button>
              </a-form-item>
            </a-form>
          </template>
          <template #right>
            <div>
              <a-button style="margin-right: 16px" @click="handleOut"
                >导出</a-button
              >
              <a-dropdown class="bt_more">
                <a-button
                  v-show="leftTree && leftTree.meterType == '02'"
                  style="margin-right: 16px"
                  >批量添加</a-button
                >
                <template #overlay>
                  <a-menu>
                    <a-menu-item>
                      <DownloadButton
                        label="下载导入模板"
                        :customFunc="customDownload"
                      ></DownloadButton>
                    </a-menu-item>
                    <a-menu-item>
                      <UploadButton
                        label="上传导入文件"
                        fileType="file"
                        :customFunc="customUpload"
                        @uploadSuccess="uploadSuccess"
                      >
                      </UploadButton>
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>

              <a-button
                v-show="leftTree && leftTree.meterType == '02'"
                type="primary"
                @click="handleAdd"
                >添加</a-button
              >
            </div>
          </template>

          <basic-table
            ref="tableRef"
            v-bind="tableConfig"
            @page-change="pageChange"
          >
          </basic-table>
        </basic-card>
      </div>
    </div>
    <a-modal
      v-model:visible="visible"
      :title="`${isEditing ? '修改' : '添加'}抄表`"
      @ok="ok"
      @cancel="cancel"
      :confirm-loading="loading"
    >
      <BasicForm
        ref="formRef"
        :labelCol="{ span: 5 }"
        :formItemsMap="formItemsMap"
        v-model:formData="formData"
      >
      </BasicForm>
    </a-modal>
  </div>
</template>
<!-- eslint-disable no-unused-vars -->

<script setup>
import { ref, onMounted, computed, watch, nextTick } from "vue";
import { reactive } from "vue";
import { BasicTable } from "@/components/basic/table";
import LeftTree from "./LeftTree.vue";
import { apiElectricityMeter } from "@/api/energyAnalysis/electricityMeter";
import dayjs from "dayjs";
import { message } from "ant-design-vue";
import _ from "lodash";
import { calc, fmt } from "a-calc/es";
import { downLoadFile } from "@/utils/excel";

const leftTree = ref();
const treeData = ref([]);

const timeType = ref("3");
const defaultTimeTime = [
  dayjs().add(-4, "d").startOf("day"),
  dayjs().add(-1, "d").endOf("day"),
];
const defaultDayTime = [dayjs().add(-4, "d"), dayjs().add(-1, "d")];
const defaultMonthTime = [dayjs().add(-4, "M"), dayjs().add(-1, "M")];
const time = ref(defaultTimeTime);

const tableConfig = reactive({
  showIndex: false,
  showSelect: false,
  pagination: true,
  isPageBottom: true,
  scroll: { y: "calc(100vh - 420px)" },
  columns: [
    {
      title: "电表编号",
      type: "text",
      key: "bizDeviceId",
    },
    {
      title: "名称",
      type: "text",
      key: "deviceName",
    },
    {
      title: "倍率",
      type: "text",
      key: "multiplyingFactor",
      width: "50px",
    },
    {
      title: "期数",
      type: "text",
      key: "time",
    },
    {
      title: "期初表显",
      type: "text",
      key: "openDisplaysValue",
    },
    {
      title: "期末表显",
      type: "text",
      key: "closeDisplaysValue",
    },
    {
      title: "本期总电量",
      type: "text",
      key: "activeTotal",
    },
    {
      title: "抄表时间",
      type: "text",
      key: "staTime",
    },
    {
      title: "抄表人员",
      type: "text",
      key: "username",
    },
    {
      title: "备注",
      type: "text",
      key: "remark",
    },
  ],
  action: {
    title: "操作",
    width: 100,
    fixed: "right",
    items: [
      {
        icon: "FormOutlined",
        iconTip: "编辑",
        type: "icon",
        onClick: (row) => {
          handleEdit(row);
        },
      },
    ],
  },
});
const tableRef = ref();

const isEditing = ref(false);
const visible = ref(false);
const loading = ref(false);
const defaultFormData = {
  bizDeviceId: "",
  multiplyingFactor: "",
  time: undefined,
  openDisplaysValue: "",
  closeDisplaysValue: "",
  activeTotal: undefined,
  remark: "",
};
const formData = reactive(_.cloneDeep(defaultFormData));
const formRef = ref();
const formItemsMap = reactive({
  bizDeviceId: {
    label: "选择电表",
    prop: "bizDeviceId",
    type: "select",
    options: [],
    required: true,
  },
  multiplyingFactor: {
    label: "电表倍率",
    prop: "multiplyingFactor",
    type: "input",
    disabled: true,
    placeholder: " ",
    required: true,
  },
  time: {
    label: "选择日期",
    prop: "time",
    type: "datePicker",
    format: "YYYY-MM-DD",
    picker: "time",
    showTime: false,
    required: true,
  },
  openDisplaysValue: {
    label: "期初表显",
    prop: "openDisplaysValue",
    type: "input",
    disabled: true,
    required: false,
  },
  closeDisplaysValue: {
    label: "期末表显",
    prop: "closeDisplaysValue",
    type: "inputNumber",
    required: true,
  },
  activeTotal: {
    label: "本期电量",
    prop: "activeTotal",
    type: "inputNumber",
    required: true,
  },
  remark: {
    label: "备注",
    prop: "remark",
    type: "textarea",
  },
});

const menuType = computed(() => {
  if (timeType.value == 3 && leftTree.value?.meterType == "01") {
    // 小时远程
    return 6;
  } else if (timeType.value == 1 && leftTree.value?.meterType == "01") {
    // 日远程
    return 3;
  } else if (timeType.value == 2 && leftTree.value?.meterType == "01") {
    // 月远程
    return 4;
  } else if (timeType.value == 1 && leftTree.value?.meterType == "02") {
    // 日手动
    return 1;
  } else if (timeType.value == 2 && leftTree.value?.meterType == "02") {
    // 月手动
    return 2;
  }
  return "";
});
const timeFormat = computed(() => {
  if (timeType.value == 1) {
    return "YYYY-MM-DD";
  }
  if (timeType.value == 2) {
    return "YYYY-MM";
  }
  if (timeType.value == 3) {
    return "YYYY-MM-DD HH";
  }
  return "YYYY-MM-DD";
});

watch(
  () => leftTree.value?.meterType,
  (newVal) => {
    if (newVal == "02") {
      timeType.value = "1";
    }
    if (newVal == "01") {
      timeType.value = "3";
    }
    initMenu();
  },
  {
    deep: true,
  }
);

watch(
  () => formData.bizDeviceId,
  (bizDeviceId) => {
    if (bizDeviceId) {
      apiElectricityMeter.meterFactor({ bizDeviceId }).then(({ result }) => {
        formData.multiplyingFactor = result;
      });
    }
  }
);
watch(
  () => [formData.bizDeviceId, formData.time],
  ([bizDeviceId, time]) => {
    if (bizDeviceId && time) {
      let func =
        timeType.value == 3
          ? "hourMeterDisplay"
          : timeType.value == 1
          ? "dayMeterDisplay"
          : "monthMeterDisplay";
      let newTime =
        timeType.value == 3 ? dayjs(time).format("YYYY-MM-DD HH") : time;
      apiElectricityMeter[func]({ bizDeviceId, time: newTime }).then(
        ({ result }) => {
          formData.openDisplaysValue = result;
        }
      );
    }
  }
);

watch(
  () => formData.closeDisplaysValue,
  (closeDisplaysValue) => {
    let start = formData.openDisplaysValue;
    console.log(22233, start);
    if (start || start === 0 || start === "0") {
      console.log(2);
      let end = closeDisplaysValue;
      let value = calc(" end - start ", { end, start });
      formData.activeTotal = value * formData.multiplyingFactor;
    }
  }
);

const initMenu = () => {
  if (menuType.value) {
    apiElectricityMeter
      .treeMenu({ meterRead: leftTree.value?.meterType })
      .then(({ result }) => {
        treeData.value = result;
        tableRef.value.paginationProps.current = 1;
        nextTick(() => {
          initTableData();
        });
      });
  }
};

const changeTimeTab = (tab) => {
  time.value =
    tab == 1 ? defaultDayTime : tab == 3 ? defaultTimeTime : defaultMonthTime;
  formItemsMap.time.format =
    tab == 1 ? "YYYY-MM-DD" : tab == 3 ? "YYYY-MM-DD HH:mm:ss" : "YYYY-MM";
  formItemsMap.time.picker = tab == 1 ? "date" : "month";

  tableRef.value.paginationProps.current = 1;
  nextTick(() => {
    initTableData();
  });
};

const initTableData = () => {
  let devices = leftTree.value.checkedKeys;
  if (devices.length) {
    let m = {
      pageNo: tableRef.value.paginationProps.current,
      pageSize: tableRef.value.paginationProps.pageSize,
      startData: dayjs(time.value[0]).format(timeFormat.value),
      endData: dayjs(time.value[1]).format(timeFormat.value),
      deviceBizIds: devices.join(","),
    };
    if (menuType.value == 6) {
      m.start = m.startData;
      m.end = m.endData;
      m.bizDeviceIds = m.deviceBizIds;
      delete m.startData;
      delete m.endData;
      delete m.deviceBizIds;
    }
    const func = [
      "dayMeterList",
      "monthMeterList",
      "dayRemoteMeterList",
      "monthRemoteMeterList",
      "",
      "hourRemoteMeterList",
    ];
    console.log(
      1234,
      menuType.value,
      leftTree.value?.meterType,
      timeType.value
    );
    apiElectricityMeter[func[menuType.value - 1]](m).then(({ result }) => {
      tableRef.value.dataSourceRef = result.records || [];
      tableRef.value.paginationProps.total = result.total;
    });
    formItemsMap.bizDeviceId.options = leftTree.value.getDevices();
  } else {
    message.warning("没有满足条件的电表!");
    tableRef.value.dataSourceRef = [];
    tableRef.value.paginationProps.total = 0;
  }
  formItemsMap.bizDeviceId.options = leftTree.value.getDevices();
};

const handleEdit = (row) => {
  visible.value = true;
  formData.bizDeviceId = row.bizDeviceId;
  formData.multiplyingFactor = row.multiplyingFactor;

  if (timeType.value == 3) {
    formItemsMap.time.format = "YYYY-MM-DD HH:mm:ss";
    formItemsMap.time.picker = null;
    formItemsMap.time.showTime = true;
    formData.time = dayjs(row.time).format("YYYY-MM-DD HH:mm:ss");
  } else {
    formData.time = dayjs(row.time).format(timeFormat.value);
    formItemsMap.time.showTime = false;
  }
  formData.openDisplaysValue = row.openDisplaysValue;
  formData.closeDisplaysValue = row.closeDisplaysValue;
  formData.activeTotal = row.activeTotal;
  formData.remark = row.remark;
  formData.id = row.id;
};

const pageChange = (pageIndex) => {
  tableRef.value.paginationProps.current = pageIndex;
  initTableData();
};

const handleAdd = () => {
  isEditing.value = false;
  visible.value = true;
  formItemsMap.bizDeviceId.options = leftTree.value.getDevices();
};

const handleOut = () => {
  let devices = leftTree.value.checkedKeys;
  let data = {
    bizDeviceIds: devices,
    meterReadCycle: timeType.value,
    start: dayjs(time.value[0]).format(timeFormat.value),
    end: dayjs(time.value[1]).format(timeFormat.value),
  };
  apiElectricityMeter.export(data).then((res) => {
    const { filename, blob } = res;
    downLoadFile(blob, decodeURI(filename));
    message.success("导出成功");
  });
};

const ok = () => {
  formRef.value.formRef.validateFields().then(() => {
    loading.value = true;
    let func = timeType.value == 1 ? "addDay" : "addMonth";
    let m = { ...formData };
    if (formData.id) {
      func = "edit";
      m.type = menuType.value;
    }
    if (timeType.value == 3) {
      m.time = dayjs(m.time).format("YYYY-MM-DD HH");
    }
    apiElectricityMeter[func](m)
      .then(() => {
        message.success(`${formData.id ? "编辑" : "新增"}成功`);
        visible.value = false;
        initTableData();
      })
      .finally(() => {
        loading.value = false;
      });
  });
};
const cancel = () => {
  for (const key in defaultFormData) {
    formData[key] = defaultFormData[key];
  }
  delete formData.id;
};

const customDownload = async () => {
  let res =  apiElectricityMeter.downTemplate();
  res.filename = '电表抄表数据导入模板'
  return res;
};
const customUpload = async (data) => {
  return await apiElectricityMeter.upload(data);
};
const uploadSuccess = () => {
  tableRef.value.paginationProps.current = 1;
  initTableData();
};
</script>
<style lang="less" scoped>
.common-wrapper {
  display: flex;
  padding: 0;
}

.content {
  display: flex;
  flex: 1;
  gap: 16px;
  padding: 0 16px;
  height: calc(100vh - 189px);
}
.bt_more {
  margin-right: 20px;
}
</style>
